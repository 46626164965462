import React from "react";
import {
  SEO,
  PageLayout,
  AnimatedSelector,
  BackgroundImages,
  MarkdownContent,
  OutboundLink,
  FishermanIcon,
  Section,
  FadeReveal,
  BasicCarousel,
  componentIterator,
  Image,
  FilteredList,
  MultipleComponentIterator,
  InternalLink,
  MultipleComponentIteratorMap,
  HorizontalImageCard,
  PriceRange,
} from "@bluefin/components";
import { Header, Button, Grid, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
  withArgs,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createServiceItemSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessFile,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.designerae.com/services/"}
        />
        <PageLayout hero={false} className={"services-page"}>
          <AnimatedSelector
            targetSelector={
              ".image.background::before, .image.background::after"
            }
            duration={1500}
            transitionType={"zoom"}
            transitionProps={{
              animating: { scale: 1 },
              preAnimating: { scale: 1.4 },
              serverSide: { scale: 1.4 },
            }}
            easing={"cubic-bezier(0,1,0.99,1)"}
            runPolicy={"RUN_ONCE_EVERY_VISIT"}
            animationKey={"team-hero-animation"}
          >
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              parentClassName={"hero-container"}
              imageClassName={"hero-image overlay"}
              carouselProps={{
                leftArrowIcon: "fancy-arrow-left",
                rightArrowIcon: "fancy-arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "services_header",
                  defaultValue: "Tailored Solutions For You",
                })}
              />
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "services_description",
                  defaultValue:
                    "The ultimate destination for beauty and self-care.",
                })}
              />
              <div className={"ctas-container"}>
                <Button
                  primary={true}
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  icon={<FishermanIcon iconName={"fancy-arrow-right"} />}
                  labelPosition={"right"}
                  content={bookingUrl.title}
                />
              </div>
            </BackgroundImages>
          </AnimatedSelector>
          <Section className={"content-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={6} className={"markdown-column"}>
                <div>
                  <FadeReveal duration={1250} triggerOnce={true}>
                    <Header
                      as={"h1"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "content_section_header",
                        defaultValue: "Services",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "content_section_description",
                      })}
                    />
                    <div className={"ctas-container"}>
                      <Button
                        secondary={true}
                        as={OutboundLink}
                        to={bookingUrl.url}
                        event={{
                          category: "Booking",
                          action: "Schedule Appointment Intent",
                        }}
                      >
                        {bookingUrl.title}
                      </Button>
                    </div>
                  </FadeReveal>
                </div>
              </Grid.Column>
              <Grid.Column width={10} className={"images-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <BasicCarousel
                    size={1}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    leftArrowIcon={"fancy-arrow-left"}
                    rightArrowIcon={"fancy-arrow-right"}
                    slide={true}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "content_section_images",
                      }),
                      component: <Image background={true} />,
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"digital-services-section"}>
            <FilteredList
              items={allFishermanBusinessMenuCategory.nodes}
              itemFilterKey={"schedule.name"}
              childItemsKey={"iterator"}
              defaultFilterValue={"All Services"}
              displayAllFilterValue={"All Services"}
              filterValues={allFishermanBusinessMenuSchedule.nodes}
              filterValueKey={"name"}
              filterValuesComponent={<Button basic={true} />}
              className={"services-filtered-list"}
              parentContainer={<div className={"services-section"} />}
            >
              <MultipleComponentIterator
                components={[
                  {
                    component: <div className={"service-container"} />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <Grid
                            className={"category-info"}
                            stackable={true}
                            columns={2}
                          />
                        ),
                        children: [
                          {
                            component: (
                              <Grid.Column
                                className={"image-column"}
                                width={6}
                              />
                            ),
                            children: [
                              {
                                component: <Image />,
                                propMap: {
                                  src: withNamedArgs({
                                    func: getBusinessFiles,
                                    args: {
                                      businessFiles:
                                        allFishermanBusinessFile.nodes,
                                      numToSelect: 1,
                                    },
                                  }),
                                },
                              },
                            ],
                          },
                          {
                            component: (
                              <Grid.Column
                                className={"info-column"}
                                width={10}
                              />
                            ),
                            children: [
                              {
                                component: (
                                  <Header className={"name"} as={"h2"} />
                                ),
                                propMap: { content: "name" },
                              },
                              {
                                component: (
                                  <MarkdownContent className={"description"} />
                                ),
                                propMap: { content: "description" },
                              },
                              {
                                component: (
                                  <Button
                                    secondary={true}
                                    content={getComponentContentNodeContent({
                                      components:
                                        fishermanBusinessWebsitePage.components,
                                      componentId:
                                        "service_category_details_button",
                                      defaultValue: "See More",
                                      enforceDefault: true,
                                    })}
                                    event={{
                                      category: "Services",
                                      action: "View Category Details",
                                    }}
                                    as={InternalLink}
                                  />
                                ),
                                propMap: {
                                  "event.label": "name",
                                  to: createServiceCategorySlug,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: (
                          <Card.Group
                            className={"category-items"}
                            stackable={true}
                            itemsPerRow={2}
                          />
                        ),
                        children: [
                          {
                            component: (
                              <MultipleComponentIteratorMap
                                lookupField={"_id"}
                                limit={null}
                                iterator={allFishermanBusinessMenuItem.nodes}
                                components={[
                                  {
                                    component: (
                                      <FadeReveal
                                        className={"ui card"}
                                        triggerOnce={true}
                                      />
                                    ),
                                    propMap: { key: "_id" },
                                    children: [
                                      {
                                        component: (
                                          <HorizontalImageCard
                                            as={InternalLink}
                                            link={true}
                                            event={{
                                              category: "Services",
                                              action: "View Item Details",
                                            }}
                                            imagePosition={"right"}
                                          />
                                        ),
                                        propMap: {
                                          image: withNamedArgs({
                                            func: getBusinessFiles,
                                            args: {
                                              businessFiles:
                                                allFishermanBusinessFile.nodes,
                                              numToSelect: 1,
                                            },
                                          }),
                                          "event.label": "name",
                                          to: withArgs({
                                            func: createServiceItemSlug,
                                            args: [
                                              allFishermanBusinessMenuCategory.nodes,
                                            ],
                                          }),
                                        },
                                        children: [
                                          {
                                            component: (
                                              <Card.Header
                                                className={"header-container"}
                                              />
                                            ),
                                            children: [
                                              {
                                                component: <Card.Header />,
                                                propMap: { content: "name" },
                                              },
                                              {
                                                component: (
                                                  <PriceRange
                                                    priceKey={"price"}
                                                    settings={{
                                                      displayType: "range",
                                                      displayZeroCost: false,
                                                    }}
                                                  />
                                                ),
                                                propMap: {
                                                  prices: "variations",
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            component: <MarkdownContent />,
                                            propMap: { content: "description" },
                                          },
                                          {
                                            component: (
                                              <FishermanIcon
                                                iconName={"fancy-arrow-right"}
                                              />
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ]}
                              />
                            ),
                            propMap: { targetValues: "items" },
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </FilteredList>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        items
        scheduleId
        schedule {
          _id
          name
        }
        files
      }
    }
    allFishermanBusinessMenuSchedule(sort: { order: ASC, fields: order }) {
      nodes {
        _id
        name
        order
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
  }
`;
